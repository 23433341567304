import '../../../styles/alice-carousel.css'
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import TeamCard from "../components/TeamCard"
import React from "react";

const Team = () => {

    const items = [
        <TeamCard
            memo="Visionnaire & Expert en création de projets leader"
            image="/img/Team/Gedik.png"
            name="Vedat GEDIK"
            work="Co-foundateur"
        />,
        <TeamCard
            memo="Expert en IA, développeur informatique & design addict"
            image="/img/Team/Seigneur.png"
            name="Matthieu SEIGNEUR"
            work="Co-foundateur"
        />,
        <TeamCard
            memo="Expert en sécurité, gestion, juridique & levée de fonds"
            image="/img/Team/Terence.png"
            name="Terence NIBA"
            work="Co-foundateur"
        />,
        <TeamCard
            memo="Expert en football, relation media & relations humaines"
            image="/img/Team/Pogba.png"
            name="Mathias POGBA"
            work="Co-fondateur"
        />,
        <TeamCard
            memo="Expert en gestion, juridique, partenariats & levée de fonds"
            image="/img/Team/Gerbreziabiher.png"
            name="Michael GEBREZIABIHER"
            work="Co-fondateur"
        />,
        <TeamCard
            memo="Acteur, scénariste & expert en réseaux sociaux"
            image="/img/Team/Toure.png"
            name="Souleymane TOURE"
            work="Co-fondateur"
        />,
        <TeamCard
            memo="Expert en logistique aéroportuaire & événementiel VIP"
            image="/img/Team/Cerruti.png"
            name="John CERRUTI"
            work="Co-fondateur"
        />
    ];

    const responsive = {
        0: { items: 1 },
        568: { items: 2 },
        1400: { items: 3 }
    };

    return (
        <div id='team' style={{marginTop:'200px'}}>
            <section className='team' >
                <h2 className='section__title'>7 partners<hr className="signes"/></h2>
            </section>
            <div>
                <AliceCarousel
                    mouseTracking
                    items={items}
                    responsive={responsive}
                    autoPlay
                    infinite
                    autoPlayInterval={2000}
                    animationDuration={2000}
                />
            </div>
        </div>

    )
}

export default Team